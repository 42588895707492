import api from "@/services/apiService"

class MarketService {

  saveGroup(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market/group', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateGroup(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'market/group/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  deleteGroup(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'market/group/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  rollbackGroup(_id) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'market/group/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  permanentDeleteGroup(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'market/group/' + _id + '/permanent')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getGroups() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/group')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  saveMarket(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateMarket(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'market/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateMarketTitle(_id, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'market/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateMarketStatus(_id, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'market/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  permanentDeleteMarket(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'market/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getMarkets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getMarket(_id) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * อัพโหลดรูปภาพหวย
   * @param  {String} _id     id ของหวย
   * @param  {String} type    ประเภทของรูปภาพ icon หรือ bg
   * @param  {formData} data   file
   * @return {Promise}
   */
  uploadMarketImage(_id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market/' + _id + '/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * ลบรูปภาพหวย
   * @param  {String} _id  id ของหวย
   * @param  {String} type ประเภทของรูปภาพ icon หรือ bg
   * @return {Promise}
   */
  deleteMarketImage(_id, type) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'market/' + _id + '/image/' + type)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  savePayRate(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market/pay-rate', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updatePayRate(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'market/pay-rate/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getPayRates() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/pay-rate')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  saveLottoSet(_id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market/' + _id + '/lottoset', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getOffPayRate() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/off-pay-rate')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  saveOffPayRate(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'market/off-pay-rate/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }
}

export default new MarketService();
