import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/manage-lotto/market',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/manage-lotto',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'market',
              name: 'MarketDashboard',
              component: () => import('@/views/manage-lotto/Dashboard')
            },
            {
              path: 'market/:marketId',
              name: 'MarketInfo',
              component: () => import('@/views/manage-lotto/MarketInfo')
            },
            {
              path: 'group',
              name: 'MarketGroup',
              component: () => import('@/views/manage-lotto/Group')
            },
            {
              path: 'pay-rate',
              name: 'LottoPayRate',
              component: () => import('@/views/manage-lotto/PayRate')
            },
            {
              path: 'off-pay-rate',
              name: 'OffPayRate',
              component: () => import('@/views/manage-lotto/OffPayRate')
            },
            {
              path: 'lottoset-rate',
              name: 'LottoSetsRate',
              component: () => import('@/views/manage-lotto/LottoSetsRate')
            }
          ]
        },
        {
          path: '/manage-game',
          component: { render (c) { return c('router-view') } },
          children: [{
            path: 'list',
            name: 'ManageGameList',
            component: () => import('@/views/manage-game/Games')
          }, {
            path: 'category',
            name: 'ManageGameCategories',
            component: () => import('@/views/manage-game/Categories')
          }, {
            path: 'creator',
            name: 'ManageGameCreators',
            component: () => import('@/views/manage-game/Creators')
          }]
        },
        {
          path: '/manage-account',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'level',
              name: 'AccountLevels',
              component: () => import('@/views/manage-account/AccountLevels')
            },
            {
              path: 'company',
              name: 'AccountCompanies',
              component: () => import('@/views/manage-account/AccountCompanies')
            }
          ]
        },
        {
          path: '/user-setting',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'lotto-group',
              name: 'UserSettingLottoGroup',
              component: () => import('@/views/user-setting/SettingGroup')
            },
            {
              path: 'lotto-market',
              name: 'UserSettingLottoMarket',
              component: () => import('@/views/user-setting/SettingMarket')
            },
            {
              path: 'payrates',
              name: 'UserSettingPayrates',
              component: () => import('@/views/user-setting/Payrates')
            },
            {
              path: 'lottoset',
              name: 'UserSettingLottosets',
              component: () => import('@/views/user-setting/Lottosets')
            },
            {
              path: 'game-available',
              name: 'UserSettingAvailableGame',
              component: () => import('@/views/user-setting/UserSettingAvailableGame')
            },
            {
              path: 'game-holder',
              name: 'UserSettingHolderGame',
              component: () => import('@/views/user-setting/UserSettingHolderGame')
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  console.log('router.beforeEach')

  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {
    next();
  }
});

export default router
